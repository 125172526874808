import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import HomePage from './Page/home';

const styles = {
  container: {
    display: "grid",       // Kích hoạt Grid
    placeItems: "center",  // Căn giữa cả chiều dọc và ngang
    width: "100vw",        // Đặt kích thước div B
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  centeredDiv: {
    width: "300px",
    height: "300px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const savedUser = localStorage.getItem("traffic_seo_user");
    if (savedUser) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = async(e) => {
    e.preventDefault();
   // const username =username; // Tên đăng nhập
    //const password = password; // Mật khẩu

    const response = await fetch("https://hoangthongtelecom.com/API/Traffic/User/Login/index.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
    });

    const result = await response.json();
    console.log(result);

    if (result.success) {
        //alert("Đăng nhập thành công!");
        const user = result.data.username;
        localStorage.setItem("traffic_seo_user",user);
        setIsLoggedIn(true);
    } else {
        alert(result.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("traffic_seo_user");
    setIsLoggedIn(false);
  };

  if (isLoggedIn) {
    const user = localStorage.getItem("traffic_seo_user");
    return (
      <HomePage/>
    );
  }
  return (
    <div style={styles.container}>
      <Card sx={{ minWidth: 300 }}>
        <CardContent>
          <form onSubmit={handleLogin}>
            <div style={{ width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <h2>Login</h2>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <div>Username</div>
              <TextField
                id="outlined-basic"
                size='small'
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
              />
              {/* <input
                type="text"
                style={{width:'100%', height:'25px' , borderRadius:'5px'}}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              /> */}
            </div>
            <div  style={{ marginBottom: '10px' }}>
              <div>Password</div>
              <TextField
                id="outlined-basic"
                size='small'
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
            </div>
            <Button fullWidth variant="contained" onClick={handleLogin}>Đăng nhập</Button>
          </form>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </div>

  );
}

export default App;
