
import React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
const ProductPage = () => {
    return (
        <div>
            <div style={{display:'flex'}}>
                <div style={{flexGrow:1}}>Danh sách sản phẩm</div>
                <Stack spacing={2} direction="row">
                    <Button variant="text">Text</Button>
                    <Button variant="contained">Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                </Stack>
            </div>

        </div>
    );
}
export default ProductPage;