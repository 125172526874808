import React, { useEffect, useState } from "react";
import '../App.css'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 6px',
    border: '1px solid',
    lineHeight: 1.1,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));
const columns = [
    { id: 'STT', label: 'STT', with: 20 },
    { id: 'code', label: 'Số ĐH', with: 30 },
    // { id: 'ngay_ct', label: 'Ngày ĐH', with: 90 },
    {
        id: 'ma_kh',
        label: 'Mã KH',
        with: 65,
        // align: 'center',

    },
    {
        id: 'ten_kh_vat',
        label: 'Tên khách hàng',
        minWidth: 150,
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'phone',
        label: 'Điện thoại',
        minWidth: 70,
        // align: 'right',
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'money',
        label: 'Tổng tiền',
        minWidth: 100,
        align: 'right',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'status',
        label: 'Đã gửi',
        minWidth: 110,
        align: 'center',
        format: (value) => value.toFixed(0),
    },
    {
        id: 'action',
        label: 'Action',
        with: 50,
        align: 'center',

    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const useStyles = makeStyles({
    textField: {
        '& .MuiInputBase-root': {
            height: '35px',  // Adjust this value to your desired height
        },
    },
});
const PayPage = () => {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [value, setValue] = React.useState(dayjs());
    const [valueNgay, setValueNgay] = React.useState(dayjs().format('YYYY-MM-DD'));
    const [open, setOpen] = React.useState(false);

    const [openMobile, setOpenMobile] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const classes = useStyles();
    const [valueCode, setValueCode] = React.useState('');
    const [valueName, setValueName] = React.useState('');
    const [valuePhone, setValuePhone] = React.useState('');
    const [valueNote, setValueNote] = React.useState('');
    const [valueOrderCode, setValueOrderCode] = React.useState(null);
    const [valuePrice, setValuePrice] = React.useState('');
    const [valueStatus, setValueStatus] = React.useState('Báo giá');
    // const container = window !== undefined ? () => window().document.body : undefined;
    // console.log(container);
    useEffect(() => {
        // Fetch dữ liệu từ API         
        if (valueNgay !== '') {
            fetchData(valueNgay)
        }

    }, []);
    const fetchData = async (valueNgay) => {
        setData([]);
        try {
            const response = await fetch('https://zns.hcrm.online/API/Order/selectPay.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ngay_ct: valueNgay,
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    const handleDateChange = (newValue) => {
        // Format the value as 'YYYY-MM-DD'
        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : null;
        setValue(newValue);
        setValueNgay(formattedDate);
        fetchData(formattedDate);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [valueRec, setValueRec] = useState("");
    const handleClickOpen = (item) => {
        setOpen(true);

        // Gọi hàm để lấy ngày dạng 'YYYYMMDD'
        const getFormattedDate = () => dayjs().format('YYYYMMDD');

        const formattedDate = getFormattedDate(); // Gọi hàm để lấy giá trị

        console.log(formattedDate); // In ra giá trị của ngày

        // Kết hợp ngày với mã đơn hàng
        setValueOrderCode(formattedDate + item.so_ct);
        const formattedNumber = Number(parseFloat(item.t_tt).toFixed(0));
        const formattedValue = formattedNumber ? formatCurrency(formattedNumber) : '';
        setValuePrice(formattedValue);
        setValueName(item.ten_kh_vat);
        setValueRec(item.stt_rec);
        setValuePhone(item.SDT_nguoi_mua);
    };

    const handleClickOpenMobile = (item) => {
        setOpenMobile(true);

        // Gọi hàm để lấy ngày dạng 'YYYYMMDD'
        const getFormattedDate = () => dayjs().format('YYYYMMDD');

        const formattedDate = getFormattedDate(); // Gọi hàm để lấy giá trị

        console.log(formattedDate); // In ra giá trị của ngày

        // Kết hợp ngày với mã đơn hàng
        setValueOrderCode(formattedDate + item.so_ct);
        const formattedNumber = Number(parseFloat(item.t_tt).toFixed(0));
        const formattedValue = formattedNumber ? formatCurrency(formattedNumber) : '';
        setValuePrice(formattedValue);
        setValueName(item.ten_kh_vat);
        setValueRec(item.stt_rec);
        setValuePhone(item.SDT_nguoi_mua);

    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseMobile = () => {
        setOpenMobile(false);
    };
    const handlePriceChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Chỉ giữ lại số
        const formattedValue = inputValue ? formatCurrency(inputValue) : ''; // Định dạng lại thành tiền tệ
        setValuePrice(formattedValue); // Cập nhật giá trị hiển thị
    };
    const handleChangeStatus = (event) => {
        setValueStatus(event.target.value);
        setValueStatusSec(event.target.value);
    };
    const formatCurrency = (value) => {
        if (!value) return '';
        return new Intl.NumberFormat('en-US').format(value);
    };
    const removeCommas = (numberString) => {
        return numberString.replace(/,/g, "");
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Kiểm tra nếu số điện thoại bắt đầu bằng "0"
        if (phoneNumber.startsWith("0")) {
            // Thay thế ký tự đầu "0" bằng "84"
            return phoneNumber.replace(/^0/, "84");
        }
        return phoneNumber; // Trả về số không thay đổi nếu không bắt đầu bằng "0"
    };

    const [valuestatusSec, setValueStatusSec] = useState("");
    const [valuePhoneSec, setValuePhoneSec] = useState("");
    const [valueCheck, setValueCheck] = useState("");
    const sendPhone = async () => {
        if (valuePhone === '') {
            setValueCheck("Vui lòng nhập số điện thoại");
            return;
        }
        if (valuePhone.length != 10) {
            setValueCheck("Số điện thoại không đúng");
            return;
        }

        // const url = 'https://hoangthongtelecom.com/API/SendZalo/v1/Send_Zns.php';
        const url = 'https://hoangthongtelecom.com/API/ZaloZns/send.php';
        const formattedPhone = formatPhoneNumber(valuePhone);
        setValuePhoneSec(valuePhone);
        const numberWithoutCommas = removeCommas(valuePrice);
        const ngayOrder = dayjs(value).format("DD/MM/YYYY");
        const requestData = {
            phone: formattedPhone,
            code: valueOrderCode,
            total: numberWithoutCommas,
            status: valueStatus,
            ngay: ngayOrder,
            name: valueName

        };
        setOpenAdd(false);
        setOpen(false);
        setOpenMobile(false);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //  'API-Key':'846071896322'
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const dataAPI = await response.json();
            const value = dataAPI.error;
            if (value === 0) {
                updateStatus();
                setValueCode('');
                setValueName('');
                setValuePhone('');
                setValueOrderCode(null);
                setValuePrice('');
                setValueThongBao("Gửi thành công");
            } else {
                setValueThongBao("Gửi không thành công");
            }
            setOpenThongBao(true);
            console.log(dataAPI);

            // setOpenMessage(true);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const updateStatus = async () => {
        try {
            const response = await fetch('https://zns.hcrm.online/API/Order/update.php', {  // Thêm 'await'
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    stt_rec: valueRec,
                    status: valuestatusSec,
                    phone: valuePhoneSec
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();  // Thêm 'await' để resolve JSON
            console.log("Update successful:", data);
            fetchData(valueNgay);

        } catch (error) {
            console.error('Fetch error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const [valueThongBao, setValueThongBao] = React.useState(false);
    const [openThongBao, setOpenThongBao] = React.useState(false);
    const handleCloseThongBao = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenThongBao(false);
    };
    function generateRandomString(length) {
        const chars = '0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }
    const handleCloseAdd = () => {
        setOpenAdd(false);
    }

    const saveOrder = async () => {
        const randomString = generateRandomString(10);
        const qr = "001wSO3" + randomString;
        const ngayOrder = dayjs(value).format("YYYY-MM-DD");
        const numberWithoutCommas = removeCommas(valuePrice);
        const data = {
            stt_rec: qr,
            so_ct: valueOrderCode,
            ngay_ct: ngayOrder,
            ngay_lct: ngayOrder,
            ma_kh: '',
            ten_kh_vat: valueName,
            dia_chi_vat: '',
            ma_so_thue: '',
            tel_nhan: valuePhone,
            SDT_nguoi_mua: valuePhone,
            t_tt: numberWithoutCommas,
            status: 'Đã thanh toán'
        };
        setOpenAdd(false);
        try {
            const response = await fetch('https://zns.hcrm.online/API/Order/add_Tay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            // sendPhone();
            setValueCode('');
            setValueName('');
            setValuePhone('');
            setValueOrderCode(null);
            setValuePrice('');
            setValueThongBao("Gửi thành công");
            console.log(result);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const saveOrderAndSend = async () => {
        const randomString = generateRandomString(10);
        const qr = "001wSO3" + randomString;
        const ngayOrder = dayjs(value).format("YYYY-MM-DD");
        const numberWithoutCommas = removeCommas(valuePrice);
        const data = {
            stt_rec: qr,
            so_ct: valueOrderCode,
            ngay_ct: ngayOrder,
            ngay_lct: ngayOrder,
            ma_kh: '',
            ten_kh_vat: valueName,
            dia_chi_vat: '',
            ma_so_thue: '',
            tel_nhan: valuePhone,
            SDT_nguoi_mua: valuePhone,
            t_tt: numberWithoutCommas,
            status: 'Đã thanh toán'
        };

        try {
            const response = await fetch('https://zns.hcrm.online/API/Order/add_Tay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            sendPhone();
            console.log(result);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return (
        <div>
            <Snackbar open={openThongBao} autoHideDuration={6000} onClose={handleCloseThongBao}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseThongBao}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {valueThongBao}
                </Alert>

            </Snackbar>
            <div className="desktop-layout">
                <div style={{ display: 'flex', marginRight: '10px' }}>
                    <div style={{ flexGrow: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={value}
                                format="YYYY-MM-DD"
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                    </div>

                    <div>
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={() => setOpenAdd(true)} color="success" variant="contained">Thêm</Button>
                    </div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '15px' }}>
                    <TableContainer sx={{ maxHeight: "80vh" }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead >
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: 'grey' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })} */}
                                {
                                    data.message === "Không có dữ liệu nào." ? <div></div> : data.map((item, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={item.so_ct}>
                                            <TableCell key={item.so_ct} >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell key={item.so_ct} >
                                                {item.so_ct}
                                            </TableCell>
                                            {/* <TableCell key={item.so_ct} >
                                                {item.ngay_ct}
                                            </TableCell> */}
                                            <TableCell key={item.so_ct} >
                                                {item.ma_kh}
                                            </TableCell>
                                            <TableCell key={item.so_ct} >
                                                {item.ten_kh_vat}
                                            </TableCell>
                                            <TableCell key={item.SDT_nguoi_mua} >
                                                {item.SDT_nguoi_mua}
                                            </TableCell>
                                            <TableCell key={item.so_ct} align="right" >
                                                {formatCurrency(item.t_tt)}
                                            </TableCell>
                                            <TableCell key={item.so_ct} style={{ fontSize: '13px' }}>
                                                {item.status}
                                            </TableCell>
                                            <TableCell key={item.so_ct} align="center" >
                                                <BootstrapButton variant="contained" disableRipple onClick={(e) => handleClickOpen(item)}>
                                                    Zalo
                                                </BootstrapButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </div>
            <div className="mobile-layout">
                <div style={{ display: 'flex' }}>
                    <div style={{flexGrow:1}}> <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={value}
                            format="YYYY-MM-DD"
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider></div>
                   
                    <div>
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={() => setOpenAdd(true)} color="success" variant="contained">Thêm</Button>
                    </div>
                </div>

                <div style={{ marginTop: '8px', color: 'blue' }}>Danh sách đơn hàng</div>
                <hr></hr>
                {
                    data.message === "Không có dữ liệu nào." ? <div></div> : data.map((item, index) => (
                        <div>

                            <Card sx={{
                                minWidth: 200, height: '110px',
                                margin: '5px',
                                cursor: 'pointer',
                                ":hover": {
                                    border: "1px solid blue",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                                }
                            }}>
                                <CardContent>
                                    <div>
                                        <div style={{ height: '50px' }}>{item.ten_kh_vat}</div>
                                        <div style={{ height: '10px' }}></div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div style={{ flexGrow: 1 }}>{formatCurrency(item.t_tt)}</div>
                                            <BootstrapButton size="small" style={{ marginTop: '-8px' }} variant="contained" disableRipple onClick={(e) => handleClickOpenMobile(item)}>
                                                Zalo
                                            </BootstrapButton>
                                        </div>

                                    </div>
                                </CardContent>

                            </Card>
                        </div>

                        // <div>
                        //     <div>{item.ten_kh_vat}</div>
                        // </div>
                    ))
                }
            </div>

            <BootstrapDialog
                onClose={handleClose}

                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Gửi tin nhắn Zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Box>
                            <div>Khách hàng</div>
                            <TextField id="outlined-basic" value={valueName} onChange={(e) => {
                                setValueName(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Box>
                            <div>Số điện thoại</div>
                            <TextField id="outlined-basic" value={valuePhone} onChange={(e) => {
                                setValuePhone(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Mã đơn</div>
                                    <TextField id="outlined-basic" value={valueOrderCode} onChange={(e) => {
                                        setValueOrderCode(e.target.value)
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box >
                                    <div>Trạng thái đơn hàng</div>

                                    <TextField id="outlined-basic" value={valueStatus} onChange={(e) => {
                                        setValueStatus(e.target.value);
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                            </Grid>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Giá tiền</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Ngày đặt hàng</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                value={value}
                                                format="DD/MM/YYYY"
                                                onChange={(newValue) => setValue(newValue)}
                                                renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>

                                </Box>
                            </Grid>

                        </Grid>

                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{ color: 'red' }}>{valueCheck}</div>
                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => sendPhone()}>
                        Gửi tin nhắn
                    </Button>
                </DialogActions>
            </BootstrapDialog>



            <BootstrapDialog
                onClose={handleCloseMobile}
                fullScreen
                aria-labelledby="customized-dialog-title"
                open={openMobile}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Gửi tin nhắn Zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseMobile}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Box>
                            <div>Khách hàng</div>
                            <TextField multiline id="outlined-multiline-flexible" maxRows={4} value={valueName}
                                onChange={(e) => {
                                    setValueName(e.target.value)
                                }}
                                fullWidth />
                        </Box>
                        <Box sx={{ height: '10px' }}></Box>
                        <Box>
                            <div>Số điện thoại</div>
                            <TextField id="outlined-basic" value={valuePhone} onChange={(e) => {
                                setValuePhone(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Grid container spacing={2}>

                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Mã đơn</div>
                                    <TextField id="outlined-basic" value={valueOrderCode} onChange={(e) => {
                                        setValueOrderCode(e.target.value)
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box >
                                    <div>Trạng thái đơn hàng</div>
                                    <TextField id="outlined-basic" value={valueStatus} onChange={(e) => {
                                        setValueStatus(e.target.value);
                                    }} fullWidth variant="outlined" className={classes.textField} />

                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={valueStatus}
                                        fullWidth
                                        sx={{ height: '40px' }}
                                        onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"Đặt hàng thành công"}>Đặt hàng thành công</MenuItem>
                                        <MenuItem value={"Đang giao"}>Đang giao</MenuItem>
                                        <MenuItem value={"Giao hàng thành công"}>Giao thành công</MenuItem>
                                        <MenuItem value={"Đã thanh toán"}>Đã thanh toán</MenuItem>
                                    </Select> */}
                                </Box>
                            </Grid>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Giá tiền</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Ngày đặt hàng</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                                renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>

                                </Box>
                            </Grid>

                        </Grid>

                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{ color: 'red' }}>{valueCheck}</div>
                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            textAlign: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button variant="contained" autoFocus onClick={() => sendPhone()}>
                                Gửi tin nhắn
                            </Button>
                        </div>

                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={() => sendPhone()}>
                        Gửi tin nhắn
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>


            <BootstrapDialog
                onClose={handleCloseAdd}

                aria-labelledby="customized-dialog-title"
                open={openAdd}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Gửi tin nhắn Zalo
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAdd}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Box>
                            <div>Khách hàng</div>
                            <TextField id="outlined-basic" value={valueName} onChange={(e) => {
                                setValueName(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Box>
                            <div>Số điện thoại</div>
                            <TextField id="outlined-basic" value={valuePhone} onChange={(e) => {
                                setValuePhone(e.target.value)
                            }} fullWidth variant="outlined" className={classes.textField} />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Mã đơn</div>
                                    <TextField id="outlined-basic" value={valueOrderCode} onChange={(e) => {
                                        setValueOrderCode(e.target.value)
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box >
                                    <div>Trạng thái đơn hàng</div>
                                    <TextField id="outlined-basic" value={valueStatus} onChange={(e) => {
                                        setValueStatus(e.target.value);
                                    }} fullWidth variant="outlined" className={classes.textField} />
                                    {/* <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={valueStatus}
                                        fullWidth
                                        sx={{ height: '40px' }}
                                        onChange={handleChangeStatus}
                                    >
                                        <MenuItem value={"Đặt hàng thành công"}>Đặt hàng thành công</MenuItem>
                                        <MenuItem value={"Đang giao"}>Đang giao</MenuItem>
                                        <MenuItem value={"Giao hàng thành công"}>Giao thành công</MenuItem>
                                        <MenuItem value={"Đã thanh toán"}>Đã thanh toán</MenuItem>
                                    </Select> */}
                                </Box>
                            </Grid>
                            <Grid size={6}>

                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Giá tiền</div>
                                    <TextField id="outlined-basic" onChange={handlePriceChange} value={valuePrice} fullWidth variant="outlined" className={classes.textField} />
                                </Box>
                                <Box sx={{ height: '10px' }}></Box>
                                <Box>
                                    <div>Ngày đặt hàng</div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                value={value}
                                                format="DD/MM/YYYY"
                                                onChange={(newValue) => setValue(newValue)}
                                                renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>

                                </Box>
                            </Grid>

                        </Grid>

                        <Box sx={{ height: '10px' }}></Box>
                        <div style={{ color: 'red' }}>{valueCheck}</div>
                        {/* <Box>
                        <div>Ngày đặt hàng</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '100%' }}>
                                <DatePicker
                                    value={value}
                                    onChange={(newValue) => setValue(newValue)}
                                    renderInput={(props) => <TextField {...props} sx={{ height: '35px' }} />}
                                />
                            </Box>
                        </LocalizationProvider>

                    </Box> */}

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" onClick={() => saveOrder()}>
                        Lưu
                    </Button>
                    <Button autoFocus variant="outlined" onClick={() => saveOrderAndSend()}>
                        Lưu và gửi tin nhắn
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
export default PayPage;